// import SanityBlockContent from "@sanity/block-content-to-react"
import React from "react"
// import { HeartIcon } from "@heroicons/react/outline"
// import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/new-design/Layout"
import { BoatDescCard, TemplateHeader } from "../components/template"
import Seo from "../components/seo"
export default function Page({ pageContext: { data }, location }) {
  // console.log(data)

  const metaImage = {
    src: data.image.asset?.url,
    width: data.image.asset?.metadata?.dimensions?.width,
    height: data.image.asset?.metadata?.dimensions?.height,
  }

  return (
    <Layout>
      <Seo
        template
        image={metaImage}
        title={data.title || data.name}
        pathname={location.pathname}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      <TemplateHeader
        name={data.name}
        image={
          data.image
            ? data.image.asset && data.image.asset.gatsbyImageData
            : null
        }
        imgSrc={data.image.asset?.url}
        length={data.length}
        capacity={data.capacity}
        link="/actions/boat/request?name="
      />
      <BoatDescCard data={data} className="pt-12" />
      {/* <Booking /> */}
    </Layout>
  )
}
